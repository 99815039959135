import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "profiles"
    }}>{`Profiles`}</h1>
    <h2 {...{
      "id": "use-case-examples"
    }}>{`Use case examples`}</h2>
    <p>{`Note, all endpoints in the customers api requires access tokens.`}</p>
    <h3 {...{
      "id": "look-up-a-profile"
    }}>{`Look up a profile`}</h3>
    <pre><code parentName="pre" {...{}}>{`curl --request GET \\
  --url 'https://api.entur.io/customers/v2/profiles/<customerIdentifier>' \\
  --header 'Authorization: Bearer <token>'
`}</code></pre>
    <h3 {...{
      "id": "create-a-new-profile"
    }}>{`Create a new profile`}</h3>
    <pre><code parentName="pre" {...{}}>{`curl --request POST \\
  --url 'https://api.entur.io/customers/v2/profiles/' \\
  --header 'Authorization: Bearer <token>' \\
  --data '{"firstName": "Ola", "surname": "Nordmann", "email": "ola@nordmann.org", "languagePreference": "NO", "createdBy": "test", "createdByChannel": "test", "organisationId": 1, "password":"abcD123"}'
`}</code></pre>
    <h3 {...{
      "id": "search-for-profiles"
    }}>{`Search for profiles`}</h3>
    <p>{`Search will return the profiles that match all search parameters. To see all available search parameters, see the api documentation.`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --request GET \\
  -- url = 'https://api.entur.io/customers/v2/profiles?email=<EMAIL>&surname=<NAME>' \\
  -- header 'Authorization: Bearer <token>'
`}</code></pre>
    <h2 {...{
      "id": "concepts-within-profiles"
    }}>{`Concepts within profiles`}</h2>
    <p>{`This guide describes basic concepts within the customers/vX/profiles api.`}</p>
    <h3 {...{
      "id": "customers"
    }}>{`Customers`}</h3>
    <p>{`A customer is a person or legal entity who has created a profile within the Customers api to gain advantages and track journeys within the Entur sales system. Each profile is connected to a specific organisation, and no organisation can gain profile data connected to a different organisation (i.e. NSB cannot view information about Entur profiles).`}</p>
    <p>{`A profile is uniquely identified by its `}<inlineCode parentName="p">{`customerNumber`}</inlineCode>{`. This references is used throughout the Entur sales system to connect a profile to its purchases, consents, loyalty programs, contact with customer support, etc.
A profile can also be uniquely identified with the combination of customerRef and organisationId. CustomerRef can be set when creating the profile, and can be used to hold an external reference originating in the partner systems.`}</p>
    <h3 {...{
      "id": "temporary-profiles"
    }}>{`Temporary profiles`}</h3>
    <p>{`Temporary profiles are used with a subsection of the customers api; /profiles/temporary. In addition, temporary profiles can be included in customer search (/profiles/customers/includeTemporary=true). All domains are allowed to create and manage temporary profiles.`}</p>
    <p>{`Temporary profiles are used to contain customer information that is necessary for a specific journey and/or ticket, when the customer does not wish to register a permanent profile. A temporary profile must have an expiration date; typically the deadline for applying for a refund or replacement tickets. All provided parameters, including expiration date, are updatable. After the profile is expired, all customer information is deleted and is not recoverable.
Temporary profiles have restricted usage within the Customers api. A temporary profile can have consents, postal addresses and preferences, but not ecards or loyalty programs.
A temporary profile can be converted to a permanent profile if done before the profile expires.`}</p>
    <h2 {...{
      "id": "authentication-and-authorization"
    }}>{`Authentication and authorization`}</h2>
    <h3 {...{
      "id": "access-controls"
    }}>{`Access controls`}</h3>
    <p>{`All of the customer endpoints are restricted by Auth0. This means that all requests must have an Authorization: Bearer `}<inlineCode parentName="p">{`<JWT-token>`}</inlineCode>{` header.`}</p>
    <p>{`This header is acquired from auth0, using service-to-service authentication. This will generate an access token with your organisation identifier:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl --request POST \\
  --url 'https://partner.entur.org/oauth/token' \\
  --header 'content-type: application/json' \\
  --data '{"grant_type":"client_credentials","client_id": "yourClientCredentials","client_secret": "yourClientSecret","audience": "https://api.entur.io"}'
`}</code></pre>
    <h3 {...{
      "id": "access-restrictions"
    }}>{`Access restrictions`}</h3>
    <p>{`We expect communication via a service, not directly from a front-end application. To this end, we've set the CORS restrictions so you can't connect directly to the API from a browser (except from these pages).`}</p>
    <p>{`The Customers APIs all check that the current client has the necessary rights before allowing access to customer data. In practice, this means that a client with a Partner-JWT-token for an organisation will only ever have access to customers in that organisation. If you try to access a customer you're not entitled to see, you will get a 404 response, as if the customer didn't exist.`}</p>
    <h2 {...{
      "id": "api-versioning"
    }}>{`API Versioning`}</h2>
    <h3 {...{
      "id": "deprecation"
    }}>{`Deprecation`}</h3>
    <p>{`The current version of the Customers API (at time of writing, v2) supports the features of the previous version (with a caveat, see below).`}</p>
    <p>{`Methods and endpoints `}<strong parentName="p">{`marked as deprecated`}</strong>{` in this version will be removed in the next version and should not be used in new code.`}</p>
    <h3 {...{
      "id": "migration-from-customers-v1-to-customers-v2"
    }}>{`Migration from customers-v1 to customers-v2:`}</h3>
    <p>{`While we have strived to maintain backwards compatibility, the handling of consents have changed quite a bit, primarily due to GDPR. To make sure this is noted by the clients, we've moved the legacy consent model from /customers/v1/consents to /customers/v2/consents/v1 .`}</p>
    <p>{`We recommend using the customers/v2/consents API going forward, as this is actively being developed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      